import { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    FormField,
    Input,
    PrimaryButton,
    NotificationBanner,
} from 'cosmos-components';
import styled from 'styled-components';

import { useRetrieveSelfCheckInForm } from './retrieveSelfCheckIn.hooks';
import {
    errorSelector,
    isLoadingSelector,
} from './retrieveSelfCheckIn.selector';
import msg from './retrieveSelfCheckInMessages';
import { useIntl } from 'react-intl';
import { LabelWithContainedModal } from 'src/components/shared/labelWithContainedModal';
import { ValidationError, ButtonWrapper } from 'src/components';
import { NotificationBannerMessage } from 'src/components/shared/NotificationBannerMessage';
import { getScreenBreaks } from 'src/utils/htmlHelper';
import { keyCodes } from 'src/keyCodes';
import { intlLink } from 'src/utils/intlHelper';
import { ThlWebChatUrl } from 'src/constants';
import { CaBrandNames, UkBrandNames, UsBrandNames } from 'src/enums';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';

const MODULE_PREFIX = 'retrieveSelfCheckIn';

export const formTestId = `${MODULE_PREFIX}-form`;
export const inputRentalRef = `${MODULE_PREFIX}-input-rentalRef`;
export const inputErrorRentalRef = `${MODULE_PREFIX}-input-error-rentalRef`;
export const inputlastName = `${MODULE_PREFIX}-input-lastName`;
export const inputErrorlastName = `${MODULE_PREFIX}-input-lastName-error`;

export const RetrieveSelfCheckInForm: FC = () => {
    const { control, setValue, onSubmit } = useRetrieveSelfCheckInForm();
    const intl = useIntl();

    const isLoading = useSelector(isLoadingSelector);
    const serverSideError = useSelector(errorSelector);
    const brandName = useSelector(sciBrandNameSelector);
    const [searchParams] = useSearchParams();

    const reference = searchParams.get('reference');
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.code === keyCodes.ENTER) {
            event.preventDefault();
            event.stopPropagation();
            onSubmit();
        }
    };

    useEffect(() => {
        if (reference && reference.length > 0) {
            setValue('reference', reference);
        }
    }, [setValue, reference]);

    const showWebChat = ![
        ...Object.values(UkBrandNames),
        ...Object.values(UsBrandNames),
    ].includes(brandName as UkBrandNames | UsBrandNames);

    const isRoadBear = brandName === UsBrandNames.roadBear;
    const isElmonte = brandName === UsBrandNames.elmonte;
    const isUK = brandName === UkBrandNames.europe;
    const isUS = isRoadBear || isElmonte;
    const isCa = brandName === CaBrandNames.canadream;

    const showErrorWithLink = !isUK && !isUS;

    return (
        <StyledForm onSubmit={onSubmit} data-test-id={formTestId}>
            <FormWrapper>
                <div>
                    <Controller
                        name="reference"
                        control={control}
                        render={({ field, fieldState: { error, invalid } }) => (
                            <>
                                <StyledFormField
                                    label={
                                        <LabelWithContainedModal
                                            label={intl.formatMessage(
                                                msg.fields.bookingReference,
                                            )}
                                            isLabelRequired
                                            modalInfo={
                                                isCa
                                                    ? intl.formatMessage(
                                                          msg.content
                                                              .modalInfoCA,
                                                      )
                                                    : intl.formatMessage(
                                                          msg.content.modalInfo,
                                                      )
                                            }
                                        />
                                    }
                                    name="reference"
                                    isGrey={isLoading}
                                >
                                    <Input
                                        testId={inputRentalRef}
                                        autoComplete="off"
                                        name="reference"
                                        onInputChange={field.onChange}
                                        placeholder={intl.formatMessage(
                                            msg.placeHolders.bookingReference,
                                        )}
                                        value={field.value}
                                        isError={invalid}
                                        onKeyDown={handleKeyDown}
                                    />
                                </StyledFormField>
                                <ValidationError
                                    data-test-id={inputErrorRentalRef}
                                >
                                    {error?.message}
                                </ValidationError>
                            </>
                        )}
                    />
                </div>
                <div>
                    <Controller
                        name="lastName"
                        control={control}
                        render={({ field, fieldState: { error, invalid } }) => (
                            <>
                                <StyledFormField
                                    isRequired
                                    label={intl.formatMessage(
                                        msg.fields.lastName,
                                    )}
                                    name={intl.formatMessage(
                                        msg.fields.lastName,
                                    )}
                                    isGrey={isLoading}
                                >
                                    <Input
                                        testId={inputlastName}
                                        autoComplete="off"
                                        name={intl.formatMessage(
                                            msg.fields.lastName,
                                        )}
                                        onInputChange={field.onChange}
                                        placeholder={intl.formatMessage(
                                            msg.placeHolders.lastName,
                                        )}
                                        isError={invalid}
                                        onKeyDown={handleKeyDown}
                                    />
                                </StyledFormField>
                                <ValidationError
                                    data-test-id={inputErrorlastName}
                                >
                                    {error?.message}
                                </ValidationError>
                            </>
                        )}
                    />
                </div>
            </FormWrapper>
            <ButtonWrapper>
                <StyledPrimaryButton isLoading={isLoading} type="submit">
                    {intl.formatMessage(msg.buttons.continue)}
                </StyledPrimaryButton>
            </ButtonWrapper>

            {serverSideError && Object.keys(serverSideError).length > 0 && (
                <StyledNotificationBanner
                    message={
                        serverSideError.code === 'notFound' ? (
                            <NotificationBannerMessage
                                header={
                                    <>
                                        {intl.formatMessage(
                                            msg.error.unableToRetrieve,
                                        )}
                                        <br />
                                        {intl.formatMessage(msg.error.tryThese)}
                                    </>
                                }
                                content={
                                    <>
                                        <StyledMessageContent>
                                            {intl.formatMessage(
                                                msg.error
                                                    .bookingReferenceApplicable,
                                            )}
                                        </StyledMessageContent>
                                        <StyledMessageContent>
                                            {intl.formatMessage(
                                                msg.error.bookingSuffix,
                                            )}
                                        </StyledMessageContent>
                                        <StyledMessageContent>
                                            {intl.formatMessage(
                                                msg.error.lastNameConfirmation,
                                            )}
                                        </StyledMessageContent>
                                        <StyledMessageContent>
                                            {intl.formatMessage(
                                                msg.error.pleaseContact,
                                            )}
                                        </StyledMessageContent>
                                    </>
                                }
                            />
                        ) : serverSideError.code === 'badRequest' &&
                          serverSideError.subCode === 'selfCheckInCompleted' ? (
                            <NotificationBannerMessage
                                header={intl.formatMessage(
                                    msg.success.registrationCompleted,
                                )}
                                content={
                                    showWebChat
                                        ? intl.formatMessage(
                                              msg.error.makeChangesWebChat,
                                          )
                                        : intl.formatMessage(
                                              msg.error.makeChanges,
                                          )
                                }
                            />
                        ) : serverSideError.code === 'badRequest' &&
                          serverSideError.subCode ===
                              'tooEarlyToStartSelfCheckIn' ? (
                            <NotificationBannerMessage
                                header={intl.formatMessage(
                                    msg.error.tooEarlyToStartSelfCheckIn,
                                )}
                                content={
                                    <>
                                        {intl.formatMessage(
                                            msg.error
                                                .tooEarlyToStartSelfCheckInDescription,
                                        )}
                                    </>
                                }
                            />
                        ) : serverSideError.code === 'badRequest' ? (
                            <NotificationBannerMessage
                                header={
                                    <strong>
                                        {intl.formatMessage(
                                            msg.error.someThingWrong,
                                        )}
                                    </strong>
                                }
                                content={
                                    <>
                                        {intl.formatMessage(
                                            msg.error.pleaseContactUs,
                                        )}
                                    </>
                                }
                            />
                        ) : serverSideError.code === 'conflict' ? (
                            <NotificationBannerMessage
                                header={
                                    <strong>
                                        {intl.formatMessage(
                                            msg.error.someThingWrong,
                                        )}
                                    </strong>
                                }
                                content={
                                    <>
                                        {showErrorWithLink
                                            ? intl.formatMessage(
                                                  msg.error.conflictWithLink,
                                                  {
                                                      a: intlLink(
                                                          ThlWebChatUrl,
                                                      ),
                                                  },
                                              )
                                            : intl.formatMessage(
                                                  msg.error.conflict,
                                              )}
                                    </>
                                }
                            />
                        ) : (
                            <></>
                        )
                    }
                    type={
                        serverSideError.code === 'badRequest' &&
                        serverSideError.subCode === 'selfCheckInCompleted'
                            ? 'success'
                            : ['badRequest', 'notFound', 'conflict'].includes(
                                  serverSideError.code,
                              )
                            ? 'error'
                            : 'success'
                    }
                    isClosable={false}
                    notificationIconSize="medium1"
                    hasBorder
                    isNotificationIconFilled
                />
            )}
        </StyledForm>
    );
};

const StyledForm = styled.form`
    padding: 0;
    margin: auto;
    padding-bottom: 6rem;
    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenMDMin}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        max-width: 60.8rem;
    }
    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        max-width: 72rem;
    }
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        max-width: 30.2rem;
    }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    position: relative;
    top: 1.5rem;
    width: 100%;
    @media (min-width: ${(props) => getScreenBreaks(props).screenMDMin}) {
        top: 3rem;
    }
`;

const StyledFormField = styled(FormField)`
    margin: 0;
    padding-top: 1.5rem;
    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        padding-right: 1.6rem;
        width: 35rem;
    }
`;

const StyledNotificationBanner = styled(NotificationBanner)`
    margin-top: 3rem;
    @media (min-width: ${(props) => getScreenBreaks(props).screenMDMin}) {
        margin-left: auto;
        margin-right: auto;
        width: 32.7rem;
        margin-top: 4.5rem;
    }
`;

const StyledMessageContent = styled.div`
    margin: 0;
    padding-top: 0.9rem;
    font-weight: 500;
`;

const FormWrapper = styled.div`
    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        display: flex;
    }
`;
