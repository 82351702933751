import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Footer } from 'src/components/shared/Footer';
import {
    Header,
    EMHeader,
    RBHeader,
    UKHeader,
    CAHeader,
} from 'src/components/shared/Header';
import { getScreenBreaks } from 'src/utils/htmlHelper';
import { UkBrandNames, UsBrandNames, CaBrandNames } from 'src/enums';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { RESET_APP_ACTION } from 'src/utils/redux';

import {
    CustomizedRetrieveSelfCheckInForm,
    StyledPage,
} from './retrieveSelfCheckIn.style';
import { retrieveSelfCheckInActionByMagicTokenAction } from './retrieveSelfCheckIn.duck';
import { LandingText } from './retrieveSelfCheckIn.landingText';
import { LandingTextCA } from './retrieveSelfCheckIn.landingTextCA';

export const RetrieveSelfCheckInComponent: FC = () => {
    const dispatch = useDispatch();
    const querySearchParams = new URLSearchParams(window.location.search);
    const magicToken = querySearchParams.get('magicToken');
    const brandName = useSelector(sciBrandNameSelector);
    const isRoadBear = brandName === UsBrandNames.roadBear;
    const isElmonte = brandName === UsBrandNames.elmonte;
    const isUk = brandName === UkBrandNames.europe;
    const isCa = brandName === CaBrandNames.canadream;

    useEffect(() => {
        dispatch({
            type: RESET_APP_ACTION,
        });
        if (magicToken) {
            dispatch(
                retrieveSelfCheckInActionByMagicTokenAction({
                    magicToken,
                }),
            );
        }
    }, [dispatch, magicToken]);

    return (
        <StyledPage
            header={
                isRoadBear ? (
                    <RBHeader />
                ) : isElmonte ? (
                    <EMHeader />
                ) : isUk ? (
                    <UKHeader />
                ) : isCa ? (
                    <CAHeader />
                ) : (
                    <Header />
                )
            }
            footer={<Footer />}
        >
            {isCa ? (
                <LandingTextCA />
            ) : (
                <LandingText showOutstandingBalance={isRoadBear || isElmonte} />
            )}
            <CustomizedRetrieveSelfCheckInForm />
        </StyledPage>
    );
};

export const StyledContent = styled.p`
    font-size: 1.4rem;
    font-weight: normal;
    margin: 0;
    padding: 1.5rem 2.4rem 0 2.4rem;
    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenMDMin}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        padding-top: 1.2rem;
        max-width: 60.8rem;
        margin: auto;
    }
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        max-width: 30.2rem;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
    }
    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        padding-left: 0;
        padding-right: 0;
    }
`;
export const StyledContentWrapper = styled.div`
    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        padding-top: 1.5rem;
        position: relative;
        top: -0.6rem;
        margin: auto;
        max-width: 72rem;
    }
`;
