import { useCallback } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { B2C } from 'cosmos-components';

import { useWindowDimensions } from 'src/hooks/useWindowDimensions';
import { DeviceType } from 'src/enums';

import * as S from './products.style';
import { MODULE_PREFIX } from './products.constants';
import {
    ConfigurationNode,
    ProductCategory,
    ConfigurationNodeGroupDisplay,
} from './products.types';

const ProductNodeChildren = ({
    childrenConfigurationNode,
    setPopupInfo,
    setVisible,
    loadingNodeId,
    hideLiabilityOnChildren,
    control,
}: {
    childrenConfigurationNode: ConfigurationNode[];
    setPopupInfo: React.Dispatch<React.SetStateAction<string>>;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    loadingNodeId?: string;
    hideLiabilityOnChildren?: boolean;
    control: Control<FieldValues, any>;
}) => {
    return (
        <>
            {childrenConfigurationNode.map((child) => {
                if (!child.product) return <></>;
                const isLoading =
                    child.id.configurationNodeId === loadingNodeId;
                const readOnly =
                    (child.readOnly && !child.product.requiresQuantity) ||
                    (child.product.minQuantity === child.quantity &&
                        child.product.maxQuantity === child.quantity);

                return (
                    <S.FormUpgradeContainer
                        key={`${MODULE_PREFIX}-ProductNodeContainerChildren-${child.id.configurationNodeId}`}
                    >
                        <Controller
                            control={control}
                            name={child.id.configurationNodeId}
                            render={({ field }) => {
                                if (!child.product) {
                                    return <></>;
                                }
                                return (
                                    <B2C.ProductCard
                                        type="checkbox"
                                        description={child.product.description}
                                        price={child.product.price}
                                        title={child.product.name}
                                        learnMoreAction={() => {
                                            setPopupInfo(
                                                child?.product
                                                    ?.popupInformation ?? '',
                                            );
                                            setVisible(true);
                                        }}
                                        loading={isLoading}
                                        prepaid={child.product.prepaid}
                                        included={child.product.included}
                                        input={{
                                            ...field,
                                            onChange: (value) => {
                                                field.onChange(value ? 1 : 0);
                                            },
                                            checked: child.quantity > 0,
                                            disabled:
                                                !!loadingNodeId || readOnly,
                                        }}
                                        liability={
                                            hideLiabilityOnChildren
                                                ? undefined
                                                : child.product.liability
                                        }
                                    />
                                );
                            }}
                        />
                    </S.FormUpgradeContainer>
                );
            })}
        </>
    );
};

export const ProductNodes = ({
    configurationNodes,
    category,
    control,
    required,
    display,
    childrenConfigurationNode,
    hideLiability,
    hideLiabilityOnChildren,
    loadingNodeId,
    setPopupInfo,
    setVisible,
}: {
    configurationNodes: ConfigurationNode[];
    childrenConfigurationNode?: ConfigurationNode[];
    category: ProductCategory;
    required?: boolean;
    display: ConfigurationNodeGroupDisplay;
    setPopupInfo: React.Dispatch<React.SetStateAction<string>>;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    hideLiability?: boolean;
    hideLiabilityOnChildren?: boolean;
    loadingNodeId?: string;
    control: Control<FieldValues, any>;
}) => {
    const { deviceType } = useWindowDimensions();
    const upgradeSelected = !!childrenConfigurationNode?.find(
        (child) => child.quantity > 0,
    );
    const firstIndexWithQuantity = configurationNodes.findIndex(
        (node) => node.quantity > 0,
    );
    const shouldDisplayChildren =
        firstIndexWithQuantity >= 0 &&
        !!childrenConfigurationNode &&
        childrenConfigurationNode.length > 0;

    const getChildComponent = useCallback(
        (index: number) => {
            if (index === firstIndexWithQuantity && shouldDisplayChildren) {
                return (
                    <ProductNodeChildren
                        childrenConfigurationNode={childrenConfigurationNode}
                        control={control}
                        setPopupInfo={setPopupInfo}
                        setVisible={setVisible}
                        hideLiabilityOnChildren={
                            hideLiabilityOnChildren || hideLiability
                        }
                        loadingNodeId={loadingNodeId}
                    />
                );
            }
            return <></>;
        },
        [
            firstIndexWithQuantity,
            shouldDisplayChildren,
            childrenConfigurationNode,
            control,
            setPopupInfo,
            setVisible,
            hideLiability,
            hideLiabilityOnChildren,
            loadingNodeId,
        ],
    );

    return (
        <S.ProductNodesContainer display={display} deviceType={deviceType}>
            {(configurationNodes ?? [])
                .sort((node1, node2) => node1.order - node2.order)
                .map((configurationNode, index) => {
                    const isLoading =
                        configurationNode.id.configurationNodeId ===
                        loadingNodeId;
                    const product = configurationNode.product;
                    const readOnly =
                        (configurationNode.readOnly &&
                            !product?.requiresQuantity &&
                            configurationNode.quantity > 0) ||
                        (product?.minQuantity === configurationNode.quantity &&
                            product?.maxQuantity ===
                                configurationNode.quantity);

                    if (configurationNode.type === 'Text') {
                        return (
                            <Controller
                                control={control}
                                name={configurationNode.id.configurationNodeId}
                                key={configurationNode.id.configurationNodeId}
                                render={({ field }) => {
                                    return (
                                        <S.ProductNodeContainer
                                            key={`${MODULE_PREFIX}-configurationNode-${configurationNode.id.configurationNodeId}`}
                                        >
                                            <B2C.TextCard
                                                input={{
                                                    ...field,
                                                    onChange: (value) => {
                                                        field.onChange(
                                                            value ? 1 : 0,
                                                        );
                                                    },
                                                    checked:
                                                        configurationNode.quantity >
                                                        0,
                                                    disabled: !!loadingNodeId,
                                                }}
                                                title={
                                                    configurationNode.text ?? ''
                                                }
                                                loading={isLoading}
                                                radioAllowDeselect={!required}
                                                key={
                                                    configurationNode.id
                                                        .configurationNodeId
                                                }
                                            />
                                            {getChildComponent(index)}
                                        </S.ProductNodeContainer>
                                    );
                                }}
                            />
                        );
                    }
                    if (!product || product.isPartnerProduct) {
                        return <></>;
                    }
                    if (category === 'Insurances' && !!product) {
                        return (
                            <Controller
                                control={control}
                                name={configurationNode.id.configurationNodeId}
                                key={configurationNode.id.configurationNodeId}
                                render={({ field }) => {
                                    return (
                                        <S.ProductNodeContainer
                                            key={`${MODULE_PREFIX}-ProductNodeContainer-${product.id.productId}`}
                                        >
                                            <B2C.ProductCard
                                                type="radio"
                                                description={
                                                    product.description
                                                }
                                                price={product.price}
                                                title={product.name}
                                                loading={isLoading}
                                                radioAllowDeselect={!required}
                                                learnMoreAction={() => {
                                                    setPopupInfo(
                                                        product.popupInformation,
                                                    );
                                                    setVisible(true);
                                                }}
                                                prepaid={product.prepaid}
                                                included={product.included}
                                                input={{
                                                    ...field,
                                                    onChange: (value) => {
                                                        field.onChange(
                                                            !value ? 1 : 0,
                                                        );
                                                    },
                                                    checked:
                                                        configurationNode.quantity >
                                                        0,
                                                    disabled:
                                                        !!loadingNodeId ||
                                                        readOnly,
                                                }}
                                                liability={
                                                    hideLiability ||
                                                    upgradeSelected
                                                        ? undefined
                                                        : product.liability
                                                }
                                            />
                                            {getChildComponent(index)}
                                        </S.ProductNodeContainer>
                                    );
                                }}
                            />
                        );
                    } else if (category === 'Extras' && !!product) {
                        return (
                            <Controller
                                control={control}
                                name={configurationNode.id.configurationNodeId}
                                key={configurationNode.id.configurationNodeId}
                                render={({ field }) => {
                                    return (
                                        <S.ProductNodeContainer
                                            key={`${MODULE_PREFIX}-ProductNodeContainer-${product.id.productId}`}
                                        >
                                            <B2C.ExtraCard
                                                title={product.name}
                                                description={
                                                    product.description ?? ''
                                                }
                                                minQuantity={
                                                    product.minQuantity
                                                }
                                                maxQuantity={
                                                    product.maxQuantity
                                                }
                                                requiresQuantity={
                                                    product.requiresQuantity
                                                }
                                                price={product.price}
                                                loading={isLoading}
                                                prepaid={product.prepaid}
                                                included={product.included}
                                                isMobile={
                                                    deviceType ===
                                                    DeviceType.Mobile
                                                }
                                                input={{
                                                    ...field,
                                                    disabled:
                                                        !!loadingNodeId ||
                                                        readOnly,
                                                }}
                                            />
                                            {getChildComponent(index)}
                                        </S.ProductNodeContainer>
                                    );
                                }}
                            />
                        );
                    }

                    return <></>;
                })}
        </S.ProductNodesContainer>
    );
};
