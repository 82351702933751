import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { B2C, CosmosEnums, Modal } from 'cosmos-components';

import { SCIApi } from 'src/apis';
import {
    DeviceType,
    HttpStatusCodeEnum,
    UsBrandNames,
    CaBrandNames,
} from 'src/enums';
import { colors } from 'src/themes';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useSci } from 'src/hooks/useSci';
import { useWindowDimensions } from 'src/hooks/useWindowDimensions';
import {
    sciBrandNameSelector,
    sciLanguageSelector,
} from 'src/components/shared/SciPage/SciPage.selector';
import { getLangCode } from 'src/utils/string';
import { CAHeader } from '../Header';
import { LoyaltyCodes } from 'src/modules/updateCustomerInfo/loyaltyMembership/loyaltyMembership.constants';
import { productsApiRoute } from 'src/modules/products/shared/products.api.hooks';

import { Footer } from '../Footer';

import { SciPageError } from './SciPage.error';
import { carouselDotsSelector } from './SciPage.selector';
import * as S from './SciPage.style';
import msg from './sciPageMessages';

const API = SCIApi.getInstance();

export interface SciPageProps {
    children: React.ReactNode;
    className?: string;
    'data-test-id'?: string;
}

export const SciPage: FC<SciPageProps> = ({
    children,
    className,
    'data-test-id': dataTestId,
}) => {
    const intl = useIntl();
    const configuration = useConfiguration();
    const bookingSummaryContainer = useRef(null);
    const carouselDots = useSelector(carouselDotsSelector);
    const brandName = useSelector(sciBrandNameSelector);
    const lng = getLangCode(useSelector(sciLanguageSelector));

    const { deviceType } = useWindowDimensions();
    const [error, setError] = useState<{
        errorType: HttpStatusCodeEnum | null;
        showError: boolean;
    }>({ errorType: null, showError: false });

    const [bookingSummaryOpen, setBookingSummaryOpen] = useState(false);

    const {
        isLoading,
        isError,
        data: sciData,
        primaryProductInfoData,
        isBookingSummaryLoading,
        bookingSummaryData,
    } = useSci();

    const qffLoyaltyProgram = bookingSummaryData?.loyaltyPrograms?.find(
        (loyaltyProgram) =>
            loyaltyProgram?.loyaltyProgram?.code === LoyaltyCodes.QFF,
    );
    const apiErrorHandler = useCallback((apiError: any) => {
        if (apiError?.config?.url === productsApiRoute) {
            setError({
                errorType: HttpStatusCodeEnum.conflict,
                showError: true,
            });
        } else if (
            [
                HttpStatusCodeEnum.conflict,
                HttpStatusCodeEnum.unAuthorized,
            ].includes(apiError?.response?.status)
        ) {
            setError({
                errorType: apiError?.response?.status,
                showError: true,
            });
        }
    }, []);

    useEffect(() => {
        API.addErrorHandler('SciPage', apiErrorHandler);
        return () => {
            API.removeErrorHandler('SciPage');
        };
    }, [apiErrorHandler]);

    useEffect(() => {
        if (isError) {
            setError({ errorType: null, showError: true });
        }
    }, [isError]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!isLoading && error.showError) {
        return <SciPageError errorType={error.errorType} />;
    }

    if (isLoading || !carouselDots) {
        return (
            <S.LoaderContainer>
                <B2C.LoaderSpinner position={'center'} />
            </S.LoaderContainer>
        );
    }

    const isRelocationAgent = sciData?.agent?.type?.relocation;
    const hideDynamicLiability =
        [...Object.values(UsBrandNames)].includes(brandName as UsBrandNames) ||
        [...Object.values(CaBrandNames)].includes(brandName as CaBrandNames) ||
        isRelocationAgent;
    const isCa = brandName === CaBrandNames.canadream;

    return (
        <S.StyledPage className={className} footer={<Footer />}>
            {isCa && (
                <S.StyledCAHeaderWrapper>
                    <CAHeader hideBanner />
                </S.StyledCAHeaderWrapper>
            )}
            <S.StyledCarouselDots
                currentPageEnum={carouselDots.currentPage}
                items={carouselDots.items}
                lng={lng}
            ></S.StyledCarouselDots>
            {deviceType !== DeviceType.Desktop && sciData && (
                <S.TravelSummaryContainer deviceType={deviceType}>
                    <B2C.TravelSummary
                        reference={sciData?.rental?.reference}
                        duration={
                            bookingSummaryData?.vehicle?.productPrice?.duration
                        }
                        price={
                            bookingSummaryData?.bookingTotalPrice?.totalPrice
                        }
                        currency={bookingSummaryData?.currency}
                        openBookingDetails={() => setBookingSummaryOpen(true)}
                        pickUpLocation={sciData?.rental?.start?.point?.name}
                        pickUpDate={sciData?.rental?.start?.date}
                        dropOffLocation={sciData?.rental?.end?.point?.name}
                        dropOffDate={sciData?.rental?.end?.date}
                        pickUpTimezone={sciData?.rental?.start?.point?.timeZone}
                        dropOffTimezone={sciData?.rental?.end?.point?.timeZone}
                        showPassengerInfo={deviceType === DeviceType.Tablet}
                        isMobile={deviceType === DeviceType.Mobile}
                        numberOfAdults={sciData?.rental?.numberOfAdults}
                        numberOfChildren={sciData?.rental?.numberOfChildren}
                        showLoyaltyPrograms={
                            configuration.functionalities.loyaltyPrograms.active
                        }
                        loyaltyPrograms={bookingSummaryData?.loyaltyPrograms}
                    />
                </S.TravelSummaryContainer>
            )}
            {sciData && (
                <S.BookingSummaryModalContainer ref={bookingSummaryContainer}>
                    <Modal
                        data-test-id="sci-booking-summary-page"
                        visible={bookingSummaryOpen}
                        closable
                        getContainer={
                            bookingSummaryContainer.current ?? undefined
                        }
                        maskStyle={{
                            backgroundColor: `${colors.lightBlack} !important`,
                            opacity: '50% !important',
                        }}
                        onClose={() => setBookingSummaryOpen(false)}
                    >
                        <B2C.BookingSummary
                            countryCode={
                                sciData.countryCode as CosmosEnums.CountryCode
                            }
                            summary={bookingSummaryData}
                            name={sciData?.rental?.primaryProductName}
                            pickUpLocation={sciData?.rental?.start?.point.name}
                            pickUpDate={sciData?.rental?.start?.date}
                            dropOffLocation={sciData?.rental?.end?.point.name}
                            dropOffDate={sciData?.rental?.end?.date}
                            reference={sciData?.rental?.reference}
                            pickUpTimezone={
                                sciData?.rental?.start?.point?.timeZone
                            }
                            dropOffTimezone={
                                sciData?.rental?.end?.point?.timeZone
                            }
                            group={sciData?.rental?.group}
                            imageUrl={
                                primaryProductInfoData?.defaultImageUrl ?? ''
                            }
                            showPassengerInfo
                            hideDynamicLiability={hideDynamicLiability}
                            showTaxBreakdown={
                                configuration.functionalities.taxBreakDown
                                    .active
                            }
                            showLoyaltyPrograms={
                                configuration.functionalities.loyaltyPrograms
                                    .active
                            }
                            numberOfAdults={sciData?.rental?.numberOfAdults}
                            numberOfChildren={sciData?.rental?.numberOfChildren}
                            loading={isBookingSummaryLoading}
                        />
                    </Modal>
                </S.BookingSummaryModalContainer>
            )}
            <S.PageContainer deviceType={deviceType}>
                <S.ChildrenContainer
                    deviceType={deviceType}
                    data-test-id={dataTestId}
                >
                    {children}
                    {configuration.functionalities.loyaltyPrograms.active &&
                        !!qffLoyaltyProgram && (
                            <S.QFFDisclamerParagraph>
                                <sup>†</sup>
                                {intl.formatMessage(msg.content.QFFDisclamer)}
                            </S.QFFDisclamerParagraph>
                        )}
                </S.ChildrenContainer>
                {deviceType === DeviceType.Desktop && sciData && (
                    <B2C.BookingSummary
                        countryCode={
                            sciData.countryCode as CosmosEnums.CountryCode
                        }
                        summary={bookingSummaryData}
                        name={sciData?.rental?.primaryProductName}
                        pickUpLocation={sciData?.rental?.start?.point.name}
                        pickUpDate={sciData?.rental?.start?.date}
                        dropOffLocation={sciData?.rental?.end?.point.name}
                        dropOffDate={sciData?.rental?.end?.date}
                        reference={sciData?.rental?.reference}
                        pickUpTimezone={sciData?.rental?.start?.point?.timeZone}
                        dropOffTimezone={sciData?.rental?.end?.point?.timeZone}
                        group={sciData?.rental?.group}
                        imageUrl={primaryProductInfoData?.defaultImageUrl ?? ''}
                        showPassengerInfo
                        hideDynamicLiability={hideDynamicLiability}
                        showTaxBreakdown={
                            configuration.functionalities.taxBreakDown.active
                        }
                        showLoyaltyPrograms={
                            configuration.functionalities.loyaltyPrograms.active
                        }
                        numberOfAdults={sciData?.rental?.numberOfAdults}
                        numberOfChildren={sciData?.rental?.numberOfChildren}
                        loading={isBookingSummaryLoading}
                    />
                )}
            </S.PageContainer>
        </S.StyledPage>
    );
};
